import React, { useState, useEffect } from "react";
import BottomHeader from "../BottomHeader";
import axios from "axios";
import { useParams } from "react-router-dom";
function BlogSingle() {
    const { id } = useParams();
    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Blog/${id}`).then(
            res => setitems(res.data.data)
        )
    }, [])

    return (
        <>
            <BottomHeader />
            {
                // items && items.map((itm , index) => {
                //     return
                <div className="max-w-screen-xl mx-auto my-10 py-5  rounded-3xl  Shadow overflow-auto h-48 sm:h-auto scrollbar-thin  scrollbar-thumb-linkColor scrollbar-thumb  scrollbar-track-transparent scrollbar-thumb-rounded-full   sm:overflow-visible">
    <div className="flex justify-center items-center w-full bg-[#F0F4F9] rounded-3xl">
                        <div className={'h-72 w-72'}>
                            <img src={`https://9jeofvls.directus.app/assets/${items.blog_image}`} className={'w-full h-full object-contain'} />
                        </div>
                    </div>
                    <h1 className="font-bold text-2xl font-press-start mt-5">{items.blog_title}</h1>
                
                    <p className=" font-medium font-press-start-lato mt-5" dangerouslySetInnerHTML={{ __html: items.blog_description }} />
                </div>
                // }) 
            }
        </>
    )
}
export default BlogSingle