import React, { useEffect, useState } from "react";
import { Link ,useParams} from "react-router-dom";
import axios from "axios";
function BottomHeader(props) {
    let {id} = useParams();
    let {slag} = useParams();
    let pth = window.location.pathname;
    console.log(pth)
    // let k = pth.lastIndexOf('/')
    let newPth = pth.slice(1, pth.length);
    // console.log(newPth);

    let slugDirectus ;

    if(newPth == ""){
        slugDirectus = "home"
    }
    else if(newPth == `blogSingle/${id}`){
        slugDirectus =  `blogSingle`
    }
   else if(newPth == `serviceSingle/${slag}`){
        slugDirectus =  `serviceSingle`
    }
  
    else{
        slugDirectus = newPth;
    }

    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/BottomHeader?filter[page_slug][_eq]=${slugDirectus}`).then(
            res => setitems(res.data.data)
        )
    }, [])
    return (
        <>

            {
                items && items.map((item , index) => {
                    return   <div className="max-w-screen-xl mx-auto Second__Blog overflow-hidden z-20 border" id="Second__Home__Page_Blog" key={index}>
                    <div
                        className="second_for_text relative z-30 md:w-2/4 font-bold sm:p-8 flex flex-col justify-center space-y-8 py-5">
                        <h1 className="text-linkColor text-2xl xl:text-5xl px-5 lg:text-4xl md:text-3xl sm:text-3xl">{item.page_title}</h1>
                        <p className="text-md sm:text-xl  font-press-start-lato px-5 text-gray-100" dangerouslySetInnerHTML={{__html: item.page_description}} />
                        <div className="flex space-x-8 mx-5">
                        {
                            item.page_links && item.page_links.map((itm) => {
                                return  <Link to={`/${itm.link}`} className="link-button">{itm.link}</Link>
                            })
                        }
                        </div>
                    </div>
                    <div className="second_for_image relative z-30 md:w-2/4 flex justify-center items-center bg-[#172940]">
                        <img src={`https://9jeofvls.directus.app/assets/${item.page_image}`} className="w-48 h-48 md:w-96 md:h-96 md:w-auto" alt="" />
                    </div>
                </div>
                } )
            }


          
        </>
    )
}
export default BottomHeader