import React from 'react';
import './index.css';
import Header from './Pages/layouts/header';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/home';
import About from './Pages/about';
import Service from './Pages/service';
import Portfolio from './Pages/portfolio';
import Blog from './Pages/blog';
import Contact from './Pages/contact';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './Pages/layouts/footer';
import ServiceSingle from './Pages/Components/Service/ServiceSingle';
import Error from './Pages/404';
import BlogSingle from './Pages/Components/Blog/BlogSingle';
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Service />} />
        <Route path='/projects' element={<Portfolio />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/serviceSingle/:slag' element={<ServiceSingle />} />
        <Route path='/blogSingle/:id' element={<BlogSingle />} />
        <Route path='*' element={<Error />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
