import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
function HomeCounter() {
    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Services`).then(
            res => setitems(res.data.data)
        )
    }, [])
    const [banners, setbanners] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Banner`).then(
            res => setbanners(res.data.data)
        )
        console.log(banners)
    }, [])
    return (
        <>
            <div className="Service__Blog">
                {
                    banners && banners.map((x, y) => {
                        if (x.slug == 'service') 
                            return <div className="md:w-1/2  relative z-40  flex flex-col justify-center space-y-4">
                                <h1 className="text-white font-press-start text-2xl xl:text-5xl lg:text-4xl md:text-3xl sm:text-3xl relative z-30">
                                    {x.title}
                                </h1>
                                <p className="text-md sm:text-xl  font-press-start-lato pr-0 md:pr-36  text-white" dangerouslySetInnerHTML={{ __html: x.description }} />
                                 

                            </div>
                        
                    })
                }

                <div className="md:w-1/2 flex flex-col space-y-4 relative z-30 mt-20 md:mt-0">
                    {
                        items && items.map((item) => {
                            return <Link to={`/serviceSingle/${item.slag}`} className="Home__Counter__Child">
                                <h3 className="Home__Counter__Child__Title">{item.service_name}</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" Link__Svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>
                            </Link>
                        })
                    }

                    {/* <Link to={'/'} className="Home__Counter__Child">
                        <h3 className="Home__Counter__Child__Title">Web Development</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" Link__Svg">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                    </Link>
                    <Link to={'/'} className="Home__Counter__Child">
                        <h3 className="Home__Counter__Child__Title">Application & Development</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" Link__Svg">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                    </Link>
                    <Link to={'/'} className="Home__Counter__Child">
                        <h3 className="Home__Counter__Child__Title">Design & Multimedia</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" Link__Svg">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                    </Link>
                    <Link to={'/'} className="Home__Counter__Child">
                        <h3 className="Home__Counter__Child__Title">Content Strategy & Development</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" Link__Svg">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                    </Link>
                    <Link to={'/'} className="Home__Counter__Child">
                        <h3 className="Home__Counter__Child__Title">SEO & SEM</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" Link__Svg">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                    </Link> */}
                </div>

            </div>
        </>
    )
}
export default HomeCounter