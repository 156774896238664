import React , {useEffect , useState} from "react";
import KaassPng from '../../../assets/img/home/kaass.png';
// let a = window.location.pathname.slice(1,window.location.pathname.length-1);
import axios from "axios";
function PortfolioContent() {

    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Projects`).then(
            res => setitems(res.data.data)
        )
    }, [])


    return (
        <>
            <div className="max-w-screen-xl mx-auto py-10  rounded-3xl  Shadow">
                
                {
                    items && items.map((itm , index) => {
                        if(index % 2 == 0){
                            return        <div className="rounded-3xl w-[90%] md:w-full mx-auto bg-[#F0F4F9]">
                            <div className="flex w-[100%] mx-auto md:w-full  flex-wrap py-6 md:flex-nowrap rounded-3xl mt-10  space-x-0 md:space-x-6">
                                <div className="md:w-1/2 h-72 md:h-auto flex flex-col px-6 md:px-12 justify-evenly">
                                    <h1 className="text-linkColorDark font-bold text-4xl font-press-start">{itm.project_title}</h1>
                                    <p className="text-black font-bold text-1.5 xl  font-press-start-lato">
                                      {itm.project_text}
                                    </p>
                                    <a href={itm.project_url} target={'_blank'} className="link-button capitalize">Visit Website</a>
                                </div>
                                <div className="md:w-1/2 mt-10 md:mt-0 h-72 px-6 md:px-6 rounded-3xl overflow-hidden">
                                    <img src={`https://9jeofvls.directus.app/assets/${itm.project_image}`} alt={'Kaass Website Png Full Height'} className={'hover:translate-y-[-80%] ease-linear transition-all duration-[3s]'} />
                                </div>
                            </div>
                        </div>
                        }else{
                            return    <div className="rounded-3xl w-[90%] md:w-full mx-auto bg-[#F0F4F9]">
                            <div className="flex w-[100%] mx-auto md:w-full  flex-wrap flex-row-reverse py-6 md:flex-nowrap rounded-3xl mt-10  space-x-0 md:space-x-6">
                                <div className="md:w-1/2 h-72 md:h-auto flex flex-col px-6 md:px-12 justify-evenly">
                                    <h1 className="text-linkColorDark font-bold text-4xl font-press-start">{itm.project_title}</h1>
                                    <p className="text-black font-bold text-1.5xl  font-press-start-lato">
                                    {itm.project_text}
                                    </p>
                                    <a href={itm.project_url} target={'_blank'} className="link-button">Visit Website</a>
                                </div>
                                <div className="md:w-1/2 mt-10 md:mt-0 h-72 px-6 md:px-0  rounded-3xl overflow-hidden">
                                    <img src={`https://9jeofvls.directus.app/assets/${itm.project_image}`} alt={'Kaass Website Png Full Height'} className={'hover:translate-y-[-80%] ease-linear transition-all duration-[3s]'} />
                                </div>
                            </div>
                        </div>
                        }
                    })
                }
                
         
             
                
              
                
            </div>
        </>
    )
}
export default PortfolioContent