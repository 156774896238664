import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
function AboutOpinion() {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

        ]

    };
    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Opinion`).then(
            res => setitems(res.data.data)
        )
    }, [])
    return (
        <>
            <div className="w-[90%] max-w-screen-xl mx-auto my-10 py-10 px-10 bg-[#F0F4F9]  relative  rounded-3xl  Shadow">
                <h1 className="About_Title">What Our Clients Say About Us</h1>
                <Slider {...settings} className={'py-10'}>

                    {
                        items && items.map((item, index) => {
                            return <div className={'px-4'} key={index}>
                                <div className="Slider_Part py-4 h-48 flex flex-col justify-between">
                                    <p className="font-press-start-lato text-md font-medium text-black">{item.opinion_text}</p>
                                    <p className="mt-3"><a href={`${item.opinion_url}`} target={'_blank'} className={'text-linkColor font-bold font-press-start'}>{item.opinion_title}</a></p>
                                </div>
                            </div>
                        })
                    }



                </Slider>
            </div>
        </>
    )
}

export default AboutOpinion